import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'
import { Swiper, SwiperSlide } from 'swiper/react'

export const Wrapper = styled.div``

export const Quote = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.colors.white};
  height: 500px px;
  padding: 24px 50px 1px;
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    .field {
      max-width: 273px;
      text-align: left;
      margin-bottom: 24px;
      .Select__label {
        font-weight: 325;
        font-size: 18px;
        line-height: 22px;
      }
      .Select__handle {
        span {
          font-size: 16px;
        }
      }
      .TextFieldComponent__Base {
        font-size: 16px;
      }
      .Select__options {
        top: 80px;
      }
    }
  }
  .button {
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    padding: 7.5px 69px;
    margin-bottom: 40px;
  }
  @media (${breakpoints.desktop.min}) {
    .content {
      flex-direction: row;
      max-width: 1040px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
    .button {
      margin-bottom: 68px;
    }
  }
`

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 30px;
  line-height: 31px;
  letter-spacing: 0.111429px;
  text-transform: uppercase;
  color: #030f1c;
`

export const Underlined = styled.div`
  height: 5px;
  width: 100px;
  background: #030f1c;
  margin-bottom: 32px;
`

export const Description = styled.div`
  font-weight: 325;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #5f6f86;
  margin-bottom: 32px;
`

export const StyledSwiper = styled(Swiper)`
  padding: 0 40px;
  margin-bottom: 50px;
  .swiper {
    &-wrapper {
      padding: 14px 0 14px 0;
    }
    &-wrapper div:last-child {
      padding-right: 10px;
    }
    &-slide {
      width: auto;
      height: 350px;
      display: flex;
      align-items: center;
      .card {
        box-shadow: 0px 4px 8px rgba(3, 15, 28, 0.15);
        border: 1px solid #e6ecf4;
        width: 227px;
        height: 274px;
        border-radius: 10px;
        margin: 0 auto;
        color: #030f1c;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .icon {
          width: 96px;
          height: 96px;
          position: absolute;
          top: -30px;
        }
        .title {
          font-weight: 325;
          font-size: 24px;
          line-height: 31px;
          margin-bottom: 24px;
          margin-top: 75px;
        }
        .count {
          font-weight: 600;
          font-size: 64px;
          line-height: 77px;
          margin-bottom: 2px;
        }
        .description {
          font-weight: 325;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    &-button {
      &-next,
      &-prev {
        opacity: 0.5;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: 200ms;
        &::after {
          font-weight: bolder;
          font-size: 18px;
        }
        &:hover {
          opacity: 0.5;
          background-color: ${({ theme }) => theme.colors.red};
        }
      }
    }
    &-pagination {
      max-width: 200px;
      left: calc(50% - 95px);
      &-bullet {
        height: 15px;
        width: 15px;
        &-active {
          background: ${({ theme }) => theme.colors.red};
          opacity: 0.75;
        }
      }
    }
  }
`
