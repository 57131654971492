import React, { useState } from 'react'
import * as Yup from 'yup'
import { FormProvider, Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { TextField, Select, Button, SelectDate, eventQuoteParams, Image } from '@ecommerce/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { SwiperSlide } from 'swiper/react'
import useQuery from '../../hooks/useQuery'
import { MlTextWithIcon } from '../../graphql/contentfulTypes'
import { Wrapper, Quote, ContentTitle, Title, Description, Underlined, StyledSwiper } from './styles'
import ContactEventForm from '../ContactEventForm'

const query = `query New($listId: String!) {
    orList(id: $listId) {
      title
      description
      nameList
      itemsCollection {
        items {
          ... on MlTextWithIcon {
            title
            text {
              json
            }
            textColor
            icon {
              url
            }
            value
          }
        }
      }
    }
  }`

export type QueryResult = {
  orList: {
    title: string
    description: string
    nameList: string[]
    itemsCollection: {
      items: MlTextWithIcon[]
    }
  }
}

export interface QuoteResultsProps extends MlTextWithIcon {
  count: number
}

type Props = {
  listId: string
}

const defaultValues: eventQuoteParams = {
  event: '',
  date: '',
  inviteNumber: '',
}

const EventQuote = ({ listId }: Props) => {
  const { data, loading } = useQuery<QueryResult>(query, { variables: { listId } })
  const [selectedEvent, setSelectedEvent] = useState('')
  const [quoteResults, setQuoteResults] = useState<QuoteResultsProps[] | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)

  const methods = useForm<eventQuoteParams>({
    resolver: yupResolver(
      Yup.object().shape({
        event: Yup.string().required('Debes completar este campo'),
        date: Yup.string().required('Debes completar este campo'),
        inviteNumber: Yup.number()
          .required('Debes completar este campo')
          .min(1, 'Este campo debe de ser un número entre 1 y 10000')
          .max(10000, 'Este campo debe de ser un número entre 1 y 10000')
          .typeError('Este campo debe de ser un número entre 1 y 10000'),
      }),
    ),
    defaultValues,
    mode: 'all',
  })
  const { control, reset, register, setValue, formState, trigger, getValues, handleSubmit } = methods
  const { dirtyFields, isValid, errors } = formState
  const events = data?.orList.nameList ?? []
  const title = data?.orList.title
  const description = data?.orList.description
  const products = data?.orList.itemsCollection.items

  const resetValues = () => {
    setSelectedEvent('')
    setStartDate(null)
    reset()
  }

  const fieldsValidate = () => !(Object.keys(dirtyFields).length === 0) && isValid

  const onSubmit = () => {
    if (fieldsValidate()) {
      const newProducts = products?.map((product) => {
        return {
          ...product,
          count: product.value ? Math.round(product.value * parseInt(getValues().inviteNumber, 10)) : 0,
        }
      })
      if (newProducts) setQuoteResults(newProducts)
    }
  }

  const onEventChange = async (selected: string) => {
    setSelectedEvent(selected)
    setValue('event', selected.valueOf().toString(), { shouldDirty: true })
    await trigger('event')
  }

  const onDateChange = async (selected: any) => {
    setValue('date', selected, { shouldDirty: true })
    await trigger('date')
  }

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Quote>
          <ContentTitle>
            <Title>{title}</Title>
            <Underlined />
            <Description>{description}</Description>
          </ContentTitle>
          <div className="content">
            <Controller
              control={control}
              name="event"
              render={() => (
                <Select
                  className="field"
                  label="Tipo de Evento"
                  status={!errors.event ? undefined : 'error'}
                  errorMessage={errors.event?.message}
                  key={selectedEvent}
                  placeholder={
                    (events && events.find((event) => event === selectedEvent)) || 'Selecciona tipo de evento'
                  }
                  onSelect={(selected) => onEventChange(selected)}
                  options={events.map((val) => ({
                    value: val,
                    label: val,
                  }))}
                />
              )}
            />
            <Controller
              control={control}
              name="date"
              render={() => (
                <SelectDate
                  errorMessage={errors.date?.message}
                  status={!errors.date ? undefined : 'error'}
                  onSelect={(selected) => onDateChange(selected)}
                  label="Fecha del evento"
                  className="field"
                  placeholder="Selecciona fecha"
                  setStartDate={setStartDate}
                  startDate={startDate}
                />
              )}
            />
            <Controller
              control={control}
              name="inviteNumber"
              render={({ value, onChange }) => (
                <TextField
                  errorMessage={errors.inviteNumber?.message}
                  status={!errors.inviteNumber ? undefined : 'error'}
                  value={value}
                  onChange={onChange}
                  label="N° de invitados"
                  disabled={false}
                  className="field"
                  placeholder="Ingresa N° invitados"
                />
              )}
            />
          </div>
          <Button
            isDisabled={!fieldsValidate()}
            disabled={!fieldsValidate()}
            className="button"
            isLoading={loading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Cotizar
          </Button>
          {quoteResults && (
            <>
              <StyledSwiper
                spaceBetween={20}
                slidesPerView="auto"
                scrollbar={false}
                pagination={{ type: 'bullets', clickable: true }}
                navigation
              >
                {quoteResults.map((product, index) => (
                  <SwiperSlide key={`${index}-${product.title}`}>
                    <div className="card">
                      <Image className="icon" src={product.icon.url} alt="icon" />
                      <div className="title">{product.title}</div>
                      <div className="count">{product.count}</div>
                      <div className="description">{documentToReactComponents(product.text.json)}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </StyledSwiper>
              <Description>
                Esta cantidad de productos es una aproximación. Llena el formulario para que podamos asesorarte
                adecuadamente.
              </Description>
            </>
          )}
        </Quote>
        <ContactEventForm quoteResults={quoteResults} />
      </FormProvider>
    </Wrapper>
  )
}

export default EventQuote
