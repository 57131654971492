import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import useTheme from '../hooks/useTheme'
import withPageTransition from '../components/withPageTransition'
import { SectionWrapper } from '../templates/event-quote'
import { PgPageProps } from '../types/PgPages'
import Layout from '../components/Layout'

const EventQuotePage = ({ pageContext, data }: PgPageProps<{ eventQuoteId: string }>) => {
  const { currentMarket, productCategories, eventQuoteId } = pageContext
  const pageData = data?.allContentfulPgPage?.edges?.[0]?.node
  const pageTheme = pageData?.theme

  const { mergeThemes } = useTheme(pageTheme?.contentful_id)

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout categories={productCategories} title={pageData.title}>
        <SectionWrapper pageId={eventQuoteId} currentCity={currentMarket} />
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(EventQuotePage)

export const query = graphql`
  query EventQuotePageQuery($eventQuoteId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $eventQuoteId } }) {
      edges {
        node {
          title
          blocks {
            title
            contentful_id
            blockType
          }
          theme {
            contentful_id
            bodyBackground
            bodyBackgroundAlt
            bodyText
            brandDefault
            brandTextDefault
            productViewWrapperBackground
            productViewContainerBackground
            layoutBackground
            buttonBackgroundPrimaryColor
            buttonPrimaryColor
            bodyBackgroundImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
