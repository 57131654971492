import React from 'react'
import { toCssPrefix, Loading, breakpoints, Market } from '@ecommerce/shared'
import styled from 'styled-components'
import useQuery from '../../hooks/useQuery'
import { ClientSideBlock, BlockType } from '../../graphql/contentfulTypes'
import { BannerSliderWidget } from '../../components/BannerSlider'
import ContactEventForm from '../../components/ContactEventForm'
import NewsCards from '../../components/News/NewsCards'
import EventQuote from '../../components/EventQuote'

const { cssPrefix, toPrefix } = toCssPrefix('SectionWrapper__')

type Props = {
  pageId: string
  currentCity: Market
}

type BlocksQueryResult = {
  pgPage: {
    blocksCollection?: {
      items?: ClientSideBlock[]
    }
  }
}

const Wrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background.bodyAlternative};
  .${cssPrefix} {
    &loader {
      min-height: 100vh;
    }
    &section {
      h1 {
        font-weight: 600;
        font-size: 42px;
        line-height: 66px;
      }
      .Banner__container {
        display: flex;
        justify-content: center;
        .image-column {
          display: none;
        }
      }
      .text-align {
        h2 {
          text-align: center;
        }
        p {
          text-align: left;
        }
      }
    }
    &banner-slider {
      padding: 0 0 0 20px;
    }
    @media (${breakpoints.desktop.min}) {
      &section {
        h1 {
          max-width: 250px;
          font-size: 55px;
        }
      }
      &banner-slider {
        padding: 0 90px;
      }
    }
  }
`

const query = `
query EventQuoteSections($id: String!) {
	pgPage(id: $id) {
  	name
    blocksCollection {
      items {
        title
        name
        blockType
        sys {
          id
        }
      }
    }
  }
}`

export const SectionWrapper = ({ pageId }: Props) => {
  const { data } = useQuery<BlocksQueryResult>(query, { variables: { id: pageId } })
  const blocks = data?.pgPage?.blocksCollection?.items ?? []

  return (
    <Wrapper>
      {data ? (
        blocks.map((block) => {
          const { id } = block.sys
          switch (block.blockType) {
            case BlockType.BANNER_SLIDER: {
              return (
                <div key={id} className={toPrefix('section')}>
                  <BannerSliderWidget listId={id} />
                </div>
              )
            }

            case BlockType.EVENT_QUOTE: {
              return <EventQuote key={block.name} listId={id} />
            }

            case BlockType.NEWS: {
              return <NewsCards key={block.name} listId={id} />
            }

            default:
              return null
          }
        })
      ) : (
        <Loading className={toPrefix('loader')} />
      )}
    </Wrapper>
  )
}
