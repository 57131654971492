import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  toCssPrefix,
  breakpoints,
  TextField,
  Select,
  TextArea,
  Button,
  showToast,
  eventQuoteContactParams,
  getEventQuoteCities,
  createQuote,
} from '@ecommerce/shared'
import * as Yup from 'yup'
import { Controller, useForm, FormProvider, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import secrets from '../../config/secrets'
import { QuoteResultsProps } from '../EventQuote'

const { cssPrefix, toPrefix } = toCssPrefix('Contact__')

const Wrapper = styled.div`
  padding: 32px 29px;
  max-width: 416px;
  margin: 0 auto;

  .${cssPrefix} {
    &header {
      color: ${({ theme }) => theme.colors.bodyText};
      .title {
        font-size: 24px;
        margin-bottom: 12px;
        line-height: 31px;
        font-weight: bold;
      }
      .description {
        font-weight: 325;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }
    &form {
      .content-left {
        width: 100%;
      }
      .content-right {
        width: 100%;
      }
      .field {
        text-align: left;
        margin-bottom: 24px;
        label {
          font-size: 13px;
          color: ${({ theme }) => theme.colors.bodyText};
        }
        input {
          padding: 16px;
        }
        .TextFieldComponent__Base {
          font-size: 16px;
        }
        .Select__handle {
          span {
            font-size: 16px;
          }
          padding: 16px;
          line-height: 25px;
          height: 56px;
        }
        .Select__options {
          top 80px;
        }
      }
      .text-area {
        textarea {
          height: 162px;
        }
      }
    }
    &button {
      border: none;
      cursor: pointer;
      padding: 7.5px 58.5px; 
      font-weight: 400;
      font-size: 17px;
      line-height: 25px;
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
      border-radius: ${({ theme }) => theme.borderRadius};
      padding: 7.5px 58px;
      margin-top: 8px;
    }
  }
  @media (${breakpoints.desktop.min}) {
    max-width: 897px;
    padding: 32px 0;
    .${cssPrefix} {
      &header {
        .title {
          margin-bottom: 12px;
        }
        .description {
          margin-bottom: 24px;
        }
      }
      &form {
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        .content-left {
          max-width: 416px;
        }
        .content-right {
          max-width: 416px;
        }
      }
    }
  }
`

const defaultValues: eventQuoteContactParams = {
  name: '',
  dni: '',
  city: '',
  email: '',
  description: '',
}

type citiesType = {
  name: string
  id: number
}

type Props = {
  quoteResults: QuoteResultsProps[] | null
}

const ContactEventForm = ({ quoteResults }: Props) => {
  const [loading, setLoading] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState('')
  const [description, setDescription] = useState('')
  const [cities, setCities] = useState<citiesType[]>([])
  const methodsProvider = useFormContext()
  const { COUNTRY } = secrets
  const methods = useForm<eventQuoteContactParams>({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string()
          .required('Debes completar este campo')
          .matches(/^[aA-zZ\s]+$/, 'solo se permiten letras')
          .max(100, 'el máximo de caracteres es de 100')
          .typeError('el máximo de caracteres es de 100'),
        dni: Yup.number().required('Debes completar este campo').typeError('Este campo debe de ser un número'),
        city: Yup.string().required('Debes completar este campo'),
        email: Yup.string().email('El formato del email no es el correcto').required('Debes completar este campo'),
        description: Yup.string()
          .required('Debes completar este campo')
          .max(200, (max) => `No debe exceder los ${max.max} caracteres`),
      }),
    ),
    defaultValues,
    mode: 'all',
  })

  const { control, reset, register, setValue, formState, trigger, getValues, handleSubmit } = methods

  const { dirtyFields, isValid, errors } = formState

  const getRegions = async () => {
    const data = await getEventQuoteCities(COUNTRY)
    setCities(data.cities)
  }

  useEffect(() => {
    getRegions()
  }, [])

  useEffect(() => {
    register({ name: 'city' })
  }, [register])

  const fieldsValidate = () => !(Object.keys(dirtyFields).length === 0) && isValid

  const resetValues = () => {
    setDescription('')
    setSelectedRegion('')
    reset()
  }

  const onSubmit = async () => {
    setLoading(true)
    const valuesProvider = methodsProvider.getValues()
    const values = getValues()
    if (fieldsValidate())
      try {
        const data = {
          eventType: valuesProvider.event ? valuesProvider.event : undefined,
          date: valuesProvider.date ? valuesProvider.date : undefined,
          inviteNumber: parseInt(valuesProvider.inviteNumber, 10)
            ? parseInt(valuesProvider.inviteNumber, 10)
            : undefined,
          name: values.name,
          cityId: parseInt(values.city, 10),
          rut: values.dni,
          email: values.email,
          message: values.description,
          metadata: {
            data: quoteResults?.map((item) => {
              return {
                name: item.title,
                count: item.count,
                value: item.value,
              }
            }),
          },
        }
        await createQuote(COUNTRY, data)
        resetValues()
        showToast({
          title: '¡Gracias por comunicarte con nosotros!',
          content: 'Tu solicitud será procesada en máximo de 48 hrs hábiles y un asesor se pondrá en contacto contigo.',
          type: 'success',
        })
      } catch (e) {
        showToast({
          title: '¡Algo salió mal! Tu cotización no pudo ser enviada.',
          content: 'Verífica tus datos he intenta nuevamente.',
          type: 'error',
        })
      }
    setLoading(false)
  }

  const onRegionChange = async (selected: string) => {
    setSelectedRegion(selected.valueOf().toString())
    setValue('city', selected.valueOf().toString(), { shouldDirty: true })
    await trigger('city')
  }

  const onDescriptionChange = async (text: string) => {
    setDescription(text.valueOf().toString())
    setValue('description', text.valueOf().toString(), { shouldDirty: true })
    await trigger('description')
  }

  return (
    <Wrapper>
      <div className={toPrefix('header')}>
        <div className="title">Contacto</div>
        <div className="description">Completa los datos y envíanos tu cotización </div>
      </div>
      <div className={toPrefix('form')}>
        <div className="content-left">
          <Controller
            control={control}
            name="name"
            render={({ value, onChange }) => (
              <TextField
                errorMessage={errors.name?.message}
                status={!errors.name ? undefined : 'error'}
                value={value}
                onChange={onChange}
                label="Nombre"
                placeholder="Ingrese su nombre"
                disabled={false}
                className="field"
              />
            )}
          />
          <Controller
            control={control}
            name="dni"
            render={({ value, onChange }) => (
              <TextField
                errorMessage={errors.dni?.message}
                status={!errors.dni ? undefined : 'error'}
                value={value}
                onChange={onChange}
                label="CI"
                placeholder="Ingrese su CI"
                disabled={false}
                className="field"
              />
            )}
          />
          <Controller
            control={control}
            name="city"
            render={() => (
              <Select
                className="field"
                label="Ciudad"
                status={!errors.city ? undefined : 'error'}
                errorMessage={errors.city?.message}
                key={selectedRegion}
                placeholder={
                  cities.find((city) => city.id === parseInt(selectedRegion, 10))?.name || 'Seleccione su ciudad'
                }
                onSelect={(selected) => onRegionChange(selected)}
                options={cities.map((val) => ({
                  value: val.id,
                  label: val.name,
                }))}
              />
            )}
          />
        </div>
        <div className="content-right">
          <Controller
            control={control}
            name="email"
            render={({ value, onChange }) => (
              <TextField
                errorMessage={errors.email?.message}
                value={value}
                onChange={onChange}
                label="Email"
                placeholder="Ingrese su email"
                disabled={false}
                className="field"
                status={!errors.email ? undefined : 'error'}
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            render={() => (
              <TextArea
                errorMessage={errors.description?.message}
                status={!errors.description ? undefined : 'error'}
                value={description}
                onChange={(e) => onDescriptionChange(e.currentTarget.value)}
                label="Detalle del Pedido"
                disabled={false}
                className="field text-area"
                placeholder="Detállanos tu pedido"
              />
            )}
          />
        </div>
      </div>
      <Button
        isDisabled={!fieldsValidate()}
        disabled={!fieldsValidate()}
        isLoading={loading}
        className={toPrefix('button')}
        type="submit"
        onClick={handleSubmit(onSubmit)}
      >
        Enviar
      </Button>
    </Wrapper>
  )
}

export default ContactEventForm
